import jQuery from "jquery";
import magnificPopup from 'magnific-popup';
import Vue from 'vue';
import moment from 'moment';

jQuery(document).ready(function() {
  $('[data-youtube]').youtube_background();
});

$('.video-pop-up').magnificPopup({
  type: 'iframe',
  mainClass: 'mfp-fade',
  removalDelay: 160,
  preloader: false,
  fixedContentPos: false
});

var app = new Vue({
  el: '#app',
  data: {
    id: '1SxQ-6tDIpMclygex0iQ9jp1HomLBf1XBceYsRljz70s',
    jobs: [],
  },
  computed: {
    hasopp() {
      return this.sorted.filter(item => item.status == 'active').length
    },
    sorted() {
      var filtered = this.jobs.filter(item => {
        return moment(item.lastdatetoapply).isAfter(moment.now()) && item.status == 'active'
      })

      return filtered;
    }
  },
  methods: {
    loadData(sheet, callback) {
      fetch(`https://gsapi.futurelabkerala.in/api?id=${this.id}&sheet=${sheet}&columns=false&integers=false`)
        .then(response => response.json())
        .then(callback)
    },
    gdrive(url) {
      try {
        var id = url.match(/drive\.google\.com\/file\/d\/(.*?)\//)[1];
        return "https://drive.google.com/thumbnail?id=" + id;

      } catch (e) {
        return "https://drive.google.com/thumbnail?id=1-JTBMH4OjOtfrueHU99D0vGIJ4pq3DWu";
      }
    }
  },
  mounted() {
    this.loadData(1, (json) => {
      this.jobs = json.rows;
    })
  }
});